import React from 'react';
import './LiveUpdates.css';
import socketIOClient from "socket.io-client";
import SmallHeader from './SmallHeader';
import Timestamp from './Timestamp';
import TrackIcons from './TrackIcons';
import FlipMove from 'react-flip-move';

class LiveUpdates extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    var players = this.props.players.slice(0, 100); // max 100 renderen


    var renderedPlayers = players.map(player => {
      return (
        <div key={player.id} className="row player">
          <div className="col-1">{player.bib}</div>
          <div className="col-1">{player.wave}</div>
          <div className="col-1">{player.category}</div>
          <div className="col-4 text-truncate">{player.name}</div>
          <div className="col-3 icons"><TrackIcons icons={player.trackParts} /></div>
          <div className="col-2"><Timestamp time={player.time} /></div>
        </div>
      );
    });

    return (
      <div className="LiveUpdates">
        <SmallHeader title={this.props.title} />
        <div className="scoreboard">
          <div className="row scoreboardheader">
            <div className="col-1">Bib</div>
            <div className="col-1">Wave</div>
            <div className="col-1">Cat.</div>
            <div className="col-4 text-truncate">Naam</div>
            <div className="col-3 icons">Parcours</div>
            <div className="col-2">{this.props.timeTitle}</div>
          </div>
          <FlipMove>
            { renderedPlayers }
          </FlipMove>
        </div>
      </div>
    );
  }
}

export default LiveUpdates;
