import moment from 'moment'
import React from 'react';
import socketIOClient from "socket.io-client";
import LiveUpdates from './LiveUpdates';

class Live extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      players: []
    };

    this.socket = null;
    this.rankingViews = [];
  }

  runSimulation() {
    var i = 0;
    var playerUpdates = [{
      id: '2',
      lastUpdateTime: 0,
      bib: '2',
      category: 'PRO',
      name: 'Sam Decrock',
      trackParts: ['swim', 't1', 'bike'],
      event: 'stoppedBiking',
      time: (45*60+12*i)*1000
    },
    {
      id: '3',
      lastUpdateTime: 0,
      bib: '3',
      category: 'D<40',
      name: 'Sofie D\'hondt',
      trackParts: ['swim'],
      event: 'stoppedSwimmming',
      time: (12*60+55*i)*1000
    },
    {
      id: '4',
      lastUpdateTime: 0,
      bib: '4',
      category: 'Kids',
      name: 'Nic',
      trackParts: ['swim', 't1', 'bike', 't2', 'run'],
      event: 'finished',
      time: (121*60+20*i)*1000
    }];

    setInterval(() => {
      playerUpdates[i % playerUpdates.length].lastUpdateTime = i;
      this.onPlayerUpdate(playerUpdates[i % playerUpdates.length]);
      i++;
    }, 2000);
  }

  componentDidMount() {
    // start simulation:
    // this.runSimulation();

    this.fetchAllPlayerUpdates();
    this.listenToSocket();
  }

  componentWillUnmount() {
    if(this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  listenToSocket() {
    if(this.socket) return; // prevent multiple connections
    this.socket = socketIOClient({path: "/socketio/livescores" });

    // some debugging statements concerning socket.io
    this.socket.on('reconnecting', seconds => {
      console.log('reconnecting in ' + seconds + ' seconds');
    });

    this.socket.on('reconnect', () => {
      console.log('reconnected');
    });

    this.socket.on('reconnect_failed', () => {
      console.log('failed to reconnect');
    });

    this.socket.on('connect', () => {
      console.log('socket connected');
    });

    this.socket.on("playerUpdate", playerUpdate => {
      this.onPlayerUpdate(playerUpdate);
    });
  }

  onPlayerUpdate(playerUpdate) {
    // console.log(playerUpdate);


    var players = this.state.players;
    var index = players.findIndex(player => player.id === playerUpdate.id);
    if(index === -1) {
      players.push(playerUpdate);
    }else{
      players[index] = playerUpdate;
    }
    this.setState({players: players});


    // var players = this.state.players;
    // players.push(playerUpdate); // just add it so it shows up as a new entry on top of the list
    // this.setState({players: players});
  }


  async fetchAllPlayerUpdates() {
    var response = await fetch(`/api/livescores/playerupdates`);
    var data = await response.json();
    this.setState({players: data.playerUpdates})
  }

  render() {
    var players = this.state.players.sort((a, b) => b.lastUpdateTime - a.lastUpdateTime);

    var finishedPlayers = this.state.players.filter(player => player.event == 'finished');
    finishedPlayers = finishedPlayers.sort((a, b) => b.lastUpdateTime - a.lastUpdateTime);

    return(
      <div>
        {!!finishedPlayers.length && <LiveUpdates players={finishedPlayers} title="Finished" timeTitle="Tijd" />}
        <LiveUpdates players={players} title="Alle Updates" timeTitle="Tussentijd" />
      </div>
    );
  }

}

export default Live