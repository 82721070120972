import React from 'react';
import './SmallHeader.css';

class SmallHeader extends React.Component {
  render() {
    return (
      <div className="SmallHeader">
        {this.props.showLogo && <div className="logo"></div>}
        <div className="title">{this.props.title}</div>
      </div>
    );
  }
}

export default SmallHeader;