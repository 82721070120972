import React from 'react';
import moment from 'moment';

class Timestamp extends React.Component {

  constructor(props) {
    super(props);
  }

  toTimestamp(time) {
    if(!time) return '00:00:00';
    return moment(time).utc().format('HH:mm:ss');
  }

  render() {
    return (
      <span>
        {this.toTimestamp(this.props.time)}
      </span>
    );
  }


}

export default Timestamp;
