import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import Live from './Live';
import Ranking from './Ranking';
import LivePositions from './LivePositions';
import AutoSwitch from './AutoSwitch';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return(
      <div className="App">
        <Router>
          <Switch>
            <Route path="/live" component={Live}/>
            <Route path="/ranking" component={Ranking} />
            <Route path="/positions/:viewIndex" component={LivePositions}/>
            <Route path="/positions">
              <Redirect to="/positions/0" />
            </Route>
            <Route path="/" component={AutoSwitch} />
          </Switch>
        </Router>


      </div>
    );
  }

}

export default App