import './App.css';
import React from 'react';
import Live from './Live';
import Ranking from './Ranking';
import socketIOClient from "socket.io-client";

class AutoSwitch extends React.Component {

  constructor(props) {
    super(props);

    // there is no issue in creating 2 socket connections I guess
    this.socket = null;

    this.startedSwitchTimer = false;

    this.state = {
      view: 'live' // live, ranking
    };
  }

  componentDidMount() {
    this.fetchAllPlayerUpdates();
    this.listenToSocket();
  }

  componentWillUnmount() {
    if(this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  listenToSocket() {
    if(this.socket) return; // prevent multiple connections
    this.socket = socketIOClient({path: "/socketio/livescores" });

    // some debugging statements concerning socket.io
    this.socket.on('reconnecting', seconds => {
      console.log('reconnecting in ' + seconds + ' seconds');
    });

    this.socket.on('reconnect', () => {
      console.log('reconnected');
    });

    this.socket.on('reconnect_failed', () => {
      console.log('failed to reconnect');
    });

    this.socket.on('connect', () => {
      console.log('socket connected');
    });

    this.socket.on("playerUpdate", playerUpdate => {
      this.onPlayerUpdate(playerUpdate);
    });
  }

  onPlayerUpdate(playerUpdate) {
     if(this.startedSwitchTimer) return;
    if(playerUpdate.event == 'finished') {
      this.switchToRankingAfterSomeTime(playerUpdate.lastUpdateTime)
    }
  }

  async fetchAllPlayerUpdates() {
    if(this.startedSwitchTimer) return;

    var response = await fetch(`/api/livescores/playerupdates`);
    var data = await response.json();

    // find if a player is already finished:
    var finishedPlayerUpdates =  data.playerUpdates.filter(update => update.event === 'finished');
    finishedPlayerUpdates.sort((a, b) => b.lastUpdateTime - a.lastUpdateTime);
    var finishedPlayerUpdate = finishedPlayerUpdates[0];

    if(!finishedPlayerUpdate) return console.log(`> No finished player yet`);
    this.startedSwitchTimer = true;
    this.switchToRankingAfterSomeTime(finishedPlayerUpdate.lastUpdateTime)
  }

  switchToRankingAfterSomeTime(timeFirstFinisher) {
    var timeSinceFirstFinisher = Date.now() - timeFirstFinisher;
    var delay = 3*60*60*1000; // 3 hours
    var waitTime = delay - timeSinceFirstFinisher;
    console.log(waitTime);
    if(waitTime < 0) waitTime = 1; // switch now

    // switch to ranking view 3 hours after the first one finishes:
    setTimeout(() => {
      this.setState({view: 'ranking'});
    }, waitTime);
  }

  render() {
    return(
      <div className="App">
        {this.state.view == 'live' && <Live socketReady={socket => this.socketReady(socket)} />}
        {this.state.view == 'ranking' && <Ranking /> }
      </div>
    );
  }

}

export default AutoSwitch