import React from 'react';

class TrackIcons extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {

    var renderedIcons = this.props.icons.map(icon => {
      return (
        <div key={icon} className={`${icon}Icon`}></div>
      );
    });

    return (
      <span>
        {renderedIcons}
      </span>
    );
  }


}

export default TrackIcons;