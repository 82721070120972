import moment from 'moment'
import React from 'react';
import {
  Link
} from 'react-router-dom';
import socketIOClient from "socket.io-client";
import LiveUpdates from './LiveUpdates';
import SeenAtUpdates from './SeenAtUpdates';

class LivePositions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      players: [],
      views: []
    };

    this.socket = null;
  }

  componentDidMount() {
    this.fetchAllPlayerUpdates();
    this.fetchViews();
    this.listenToSocket();
  }

  componentWillUnmount() {
    if(this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  listenToSocket() {
    if(this.socket) return; // prevent multiple connections
    this.socket = socketIOClient({path: "/socketio/livescores" });

    // some debugging statements concerning socket.io
    this.socket.on('reconnecting', seconds => {
      console.log('reconnecting in ' + seconds + ' seconds');
    });

    this.socket.on('reconnect', () => {
      console.log('reconnected');
    });

    this.socket.on('reconnect_failed', () => {
      console.log('failed to reconnect');
    });

    this.socket.on('connect', () => {
      console.log('socket connected');
    });

    this.socket.on("playerUpdate", playerUpdate => {
      this.onPlayerUpdate(playerUpdate);
    });
  }

  onPlayerUpdate(playerUpdate) {
    // console.log(playerUpdate);

    var players = this.state.players;
    var index = players.findIndex(player => player.id === playerUpdate.id);
    if(index === -1) {
      players.push(playerUpdate);
    }else{
      players[index] = playerUpdate;
    }
    this.setState({players: players});
  }

  async fetchViews() {
    var games = (await (await fetch(`/api/results/games`)).json()).games;

    var views = [];

    var namePrefix = '';

    for(var game of games) {
      if(game.id == 2) namePrefix = `${game.name}: `;

      views.push({
        name: `${game.name}`,
        game: game,
        categoryExclusionRegex: /trio/i
      });

      views.push({
        name: `${namePrefix}Mannen`,
        game: game,
        sex: 'male',
        categoryExclusionRegex: /trio/i
      });

      views.push({
        name: `${namePrefix}Vrouwen`,
        game: game,
        sex: 'female',
        categoryExclusionRegex: /trio/i
      });

      var categories = (await (await fetch(`/api/results/games/${game.id}/categories`)).json()).categories;
      for(var category of categories) {
        views.push({
          name: `${namePrefix}${category}`,
          game: game,
          category: category
        });
      }

      views.push({
        name: `Rode Lantaarn`,
        orderByLastUpdateTime: true
      });
    }

    this.setState({views: views})
  }

  async fetchAllPlayerUpdates() {
    var response = await fetch(`/api/livescores/playerupdates`);
    var data = await response.json();
    this.setState({players: data.playerUpdates})
  }

  renderNavigation() {
    var navs = this.state.views.map((view, index) => {
      return (
        <li className="nav-item" key={view.name}>
          <Link to={`/positions/${index}`} className={`nav-link ${this.props.match.params.viewIndex == index?'active':''}`}>{view.name}</Link>
        </li>
      );
    });

    return (
      <ul className="nav md-tabs nav-justified primary-color lighten-2 mx-0 mb-0 mt-0 liveheader">
        {navs}
      </ul>
    )
  }

  render() {
    document.title = "Live Positions";
    if(this.state.views.length == 0) return;

    var view = this.state.views[this.props.match.params.viewIndex];
    if(!view) return "";

    document.title = `Live Positions - ${view.name}`;

    var players = this.state.players;
    // filter based on view:
    players = players.filter(player => {
      if(view.game && player.gameId != view.game.id) return false;
      if(view.sex && player.sex != view.sex) return false;
      if(view.categoryExclusionRegex && player.category.match(view.categoryExclusionRegex)) return false;
      if(view.category && player.category != view.category) return false;
      return true;
    });

    if(!view.orderByLastUpdateTime) {

      // order by time, the smaller your time, the better you score in the race
      players = players.sort((p1, p2) => {
        return p1.time - p2.time;
      });

      // order by event, the further you are in the race, the higher you are in the ranking
      var eventOrder = [
        'stoppedSwimming',
        'startedBiking',
        'stoppedBiking',
        'startedRunning',
        'finished'
      ];

      players = players.sort((p1, p2) => {
        var i1 = eventOrder.indexOf(p1.event);
        var i2 = eventOrder.indexOf(p2.event);
        return i2 - i1;
      });

      return(
        <div>
          {this.renderNavigation()}
          <LiveUpdates players={players} title={view.name} timeTitle="Tijd" />
        </div>
      );

    }else{


      players = players.sort((p1, p2) => {
        return p2.lastUpdateTime - p1.lastUpdateTime;
      });


      var finishedPlayers = players.filter(player => player.event == 'finished').slice(0, 1); // show the last one arrived
      var unfinishedPlayers = players.filter(player =>  player.event != 'finished');

      // it's a bit of black magic to choose in which order they should appear

      // the point is that the higher in the ranking, the best chances of still arriving in that order
      unfinishedPlayers = unfinishedPlayers.sort((p1, p2) => {
        return p2.lastUpdateTime - p1.lastUpdateTime;
      });

      // // players from the last wave have more chance of arriving:
      // unfinishedPlayers = unfinishedPlayers.sort((p1, p2) => {
      //   return p2.wave - p1.wave;
      // });
      // removed, makes it confusing

      var eventOrder = [
        'stoppedSwimming',
        'startedBiking',
        'stoppedBiking',
        'startedRunning',
        'finished'
      ];

      players = unfinishedPlayers.sort((p1, p2) => {
        var i1 = eventOrder.indexOf(p1.event);
        var i2 = eventOrder.indexOf(p2.event);
        return i2 - i1;
      });




      players = [...finishedPlayers, ...unfinishedPlayers];

      console.log('de laatste', players);

      return(
        <div>
          {this.renderNavigation()}
          <SeenAtUpdates players={players} title={view.name} />
        </div>
      );

    }
  }
}

export default LivePositions
