import React from 'react';
import './Ranking.css';
import Header from './Header';
import Timestamp from './Timestamp';

class Ranking extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentView: null,
      players: [],
      animate: false
    }

    this.views = [];
    this.currentViewIndex = -1;

    this.rankingInterval = 5 * 1000;
    this.loop = false;

    this.speed = 1.5;
  }

  componentDidMount() {
    this.fetchRankingViews();
  }

  componentWillUnmount() {
    this.loop = false;
  }

  async fetchRankingViews() {
    this.views = [];


    var games = (await (await fetch(`/api/results/games`)).json()).games;

    // first we create a view per game with ALL results:
    // for(var game of games) {
    //   this.views.push({
    //     title: game.name,
    //     subtitle: '',
    //     endpoint: `/api/results/games/${game.id}`,
    //     rankType: 'ranks'
    //   });
    // }
    // not very usefull, conmmenting

    // next we create a view per category per game:
    for(var game of games) {
      var categories = (await (await fetch(`/api/results/games/${game.id}/categories`)).json()).categories;
      for(var category of categories) {
        this.views.push({
          title: game.name,
          category: category,
          endpoint: `/api/results/games/${game.id}/categories/${category}`,
          rankType: 'subRanks'
        });
      }
    }

    this.loop = true;
    this.fetchNextView();

  }

  async fetchNextView() {
    this.currentViewIndex++;
    this.currentViewIndex = this.currentViewIndex % this.views.length;

    var currentView = this.views[this.currentViewIndex];
    var players = (await (await fetch(currentView.endpoint)).json()).players;

    this.setState({
      currentView: currentView,
      players: players,
      animate: false  // stop animation if that was the case
    });

    if(this.loop == false) return;

    var playersThatWillBeShown = players.filter(p => p[currentView.rankType].total != null);
    if(playersThatWillBeShown == 0) {
      // go to next view faster:
      setTimeout(()=>{
        this.fetchNextView();
      }, 1000);
    }else{
      // start animating after 2 seconds:
      setTimeout(()=>{
        this.setState({
          animate: true
        });

        // go to next view when animation is done:

        var animationSeconds = playersThatWillBeShown.length * (1.00 / this.speed);



        setTimeout(()=>{
          this.fetchNextView();
        }, animationSeconds*1000);
      }, 2000);
    }
  }

  render() {
    document.title = "Ranking";
    if(!this.state.currentView) return '';


    // we only want players with a total rank (rank or subRank depending on the view):
    var players = this.state.players.filter(p => p[this.state.currentView.rankType].total != null);

    // sort them by this rank:
    players = players.sort((p1, p2) => p1[this.state.currentView.rankType].total - p2[this.state.currentView.rankType].total);

    var renderedPlayers = players.map(player => {
      return (
        <div key={player._id} className="row player">
          <div className="col-1 text-truncate">{player.bib}</div>
          <div className="col-1 text-truncate">{player.category}</div>
          <div className="col-5 text-truncate">{player.name}</div>
          <div className="col-2 text-truncate"><Timestamp time={player.results.total} /></div>
          <div className="col-2 text-truncate">{player.subRanks.total}</div>
          <div className="col-1 text-truncate">{player.ranks.total}</div>
        </div>
      );
    });

    var style = {};

    if(this.state.animate) {
      var animationSeconds = players.length * (1.00 / this.speed);
      var style = {
        transition: 'all ' + animationSeconds + 's linear',
        transform: 'translate3d(0, -100%, 0) scale3d(1, 1, 1)'
      };
    }

    return (
      <div className="Ranking">
        <Header title={this.state.currentView.title} subtitle={this.state.currentView.category} />
        <div className="scoreboard">
          <div className="row scoreboardheader">
            <div className="col-1 text-truncate">Bib</div>
            <div className="col-1 text-truncate">Cat.</div>
            <div className="col-5 text-truncate">Naam</div>
            <div className="col-2 text-truncate">Tijd</div>
            <div className="col-2 text-truncate">{this.state.currentView.category} rang</div>
            <div className="col-1 text-truncate">Rang</div>
          </div>
          <div className="row playersContainer">
            <div className="col-12 players" style={style}>
              {renderedPlayers}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ranking;
